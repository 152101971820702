<template>
  <div class="my-investments-list">
    <div class="container-xl">
      <div class="card mt-3 mb-3">
        <div class="card-header">
          <div class="card-title">Мои проекты</div>
        </div>
        <div class="card-body block-pending">
          <div class="card-subtitle">В ожидании</div>
          <paginated-list :count="pendingCollection.count"
                          :total-count="pendingCollection.totalCount"
                          :data-loading="pendingCollection.loading"
                          :prefix="pendingCollection.prefix"
                          @update="loadPendingCollection"
          >
            <div v-for="(item, index) in pendingCollection.items" class="row row-body">
              <div class="col-toggle order-1 order-md-first">
                <button @click="toggleProjectVisible('pending', index)" type="button" class="btn btn-sm btn-light">
                  <i :class="{
                    'bi-chevron-up': isProjectVisible('pending', index),
                    'bi-chevron-down': !isProjectVisible('pending', index)
                  }" class="bi"></i>
                </button>
              </div>
              <div class="col-name order-0">
                <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">Проект</div>
                <router-link :to="{ name: 'primaryMarketProject', params: { uuid: item.project.uuid } }">
                  {{ item.project.name }}
                </router-link>
                <div class="small text-muted">ID {{ item.project.paymentCode }}</div>
                <span v-if="hasDoubleRate(item)"
                      class="rounded border border-success p-1 mt-2">Удвоенная доходность!
                  <c-tooltip text="Для вас действует специальное предложение по этому проекту"/>
                </span>
              </div>
              <div class="col-investment-money order-1">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Размер участия</div>
                <money :money="item.investedSum"/>
              </div>
              <div class="col-interest-rate order-2">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Ставка</div>
                {{ item.project.interestRate }}%
              </div>
              <div class="col-progress order-last order-md-3">
                <div class="row">
                  <div class="col-6">
                    <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Набрано</div>
                    <div>
                      <money :money="item.project.investedSum"/>
                    </div>
                  </div>
                  <div class="col-6 text-right">
                    <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Цель</div>
                    <div>
                      <money :money="item.project.targetSum"/>
                    </div>
                  </div>
                </div>
                <div class="progress">
                  <div class="progress-bar bg-success"
                       role="progressbar"
                       :style="{ width: (item.project.investedSum.amount / item.project.targetSum.amount * 100) + '%' }"
                       :aria-valuenow="Math.round(item.project.investedSum.amount / item.project.targetSum.amount * 100)"
                       aria-valuemin="0"
                       aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="col-initial-term order-4">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Срок</div>
                {{ item.project.initialTerm }}
              </div>
              <div class="col-left-days order-5">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Осталось</div>
                {{ item.project.maturityDate ? calculateDaysLeft(item.project.maturityDate) : "0" }}
              </div>
              <div v-if="isProjectVisible('pending', index)" class="investment-list col-12 order-last">
                <div v-for="investment in item.investments" class="row">
                  <div class="col-investment-date text-muted small">
                    <date-format :date="investment.createdAt"/>
                  </div>
                  <div class="col-investment-money">
                    <money :money="investment.money"/>
                  </div>
                </div>
              </div>
            </div>
          </paginated-list>
        </div>
        <div class="card-body block-active">
          <div class="h6">Активные</div>
          <paginated-list :count="activeCollection.count"
                          :total-count="activeCollection.totalCount"
                          :data-loading="activeCollection.loading"
                          :prefix="activeCollection.prefix"
                          @update="loadActiveCollection"
                          ref="activeList"
          >
            <div v-for="(item, index) in activeCollection.items" class="row row-body">
              <div class="col-toggle order-2 order-md-0">
                <button @click="toggleProjectVisible('active', index)" type="button" class="btn btn-sm btn-light">
                  <i :class="{
                    'bi-chevron-up': isProjectVisible('active', index),
                    'bi-chevron-down': !isProjectVisible('active', index)
                  }" class="bi"></i>
                </button>
              </div>
              <div class="col-name order-0 order-md-1">
                <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">Проект</div>
                <router-link :to="{ name: 'primaryMarketProject', params: { uuid: item.project.uuid } }">
                  {{ item.project.name }}
                </router-link>
                <div class="small text-muted">ID {{ item.project.paymentCode }}</div>
                <span v-if="hasDoubleRate(item)"
                      class="rounded border border-success p-1 mt-2">Удвоенная доходность!
                  <c-tooltip text="Для вас действует специальное предложение по этому проекту"/>
                </span>
              </div>
              <div class="col-investment-money order-3 order-lg-2">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Инвестиция</div>
                <money :money="item.investedSum"/>
              </div>
              <div class="col-interest-rate order-4 order-lg-3">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Ставка</div>
                {{ calculateProjectPercent(item.project, item.investments) }}%
              </div>
              <div class="col-month order-1 order-md-2 order-lg-4">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Месяц</div>
                {{ item.nextRepayment ? item.nextRepayment.month : item.project.initialTerm }}
                / {{ item.project.initialTerm }}
              </div>
              <div class="col-next-payment-sum order-5 order-lg-5">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Следующий платеж</div>
                <money v-if="item.nextRepayment" :money="item.nextRepayment.total"/>
                <span v-if="!item.nextRepayment">-</span>
              </div>
              <div :class="{ 'text-danger': item.nextRepayment && item.nextRepayment.overdue }"
                   class="col-next-payment-date order-6 order-md-3 order-lg-6"
              >
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Дата сл. платежа</div>
                <date-format v-if="item.nextRepayment" :date="item.nextRepayment.date"/>
                <div v-if="item.nextRepayment && item.nextRepayment.overdue" class="small">Просрочка</div>
                <span v-if="!item.nextRepayment">-</span>
              </div>
              <div class="col-actions order-7">
                <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">Вторичный
                  рынок
                </div>
              </div>
              <div v-if="isProjectVisible('active', index)" class="investment-list col-12 order-last">
                <div v-for="investment in item.investments" class="row mt-3">
                  <div class="col-buy-back order-4 order-lg-1">
                    <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">&nbsp;
                    </div>
                    <img v-if="investment.guarantorPercent" src="@/assets/img/bay_back.png" alt="Buy Back">
                  </div>
                  <div class="col-investment-date text-muted small order-3 order-lg-2">
                    <date-format :date="investment.createdAt"/>
                  </div>
                  <div class="col-investment-money order-0 order-lg-3">
                    <div>
                      <money :money="investment.money"/>
                    </div>
                    <div v-if="investment.principalRepayment">
                      <div v-if="investment.principalRepayment.amount > 0"
                           class="text-muted small"
                      >
                        <div>Осталось</div>
                        <money :money="investmentLeft(investment)"/>
                      </div>
                    </div>
                  </div>
                  <div class="col-interest-rate order-1 order-lg-4">
                    {{ item.project.interestRate - investment.guarantorPercent }}%
                  </div>
                  <div class="col-next-payment-sum order-2 order-lg-5">
                    <money v-if="investment.nextRepaymentSum" :money="investment.nextRepaymentSum"/>
                    <span v-if="!investment.nextRepaymentSum">-</span>
                  </div>
                  <div class="col-actions order-last">
                    <router-link
                        v-if="investment.sumForSale && investment.sumForSale.amount > 0 && !investment.guarantorPercent && investmentAvailableForSale(investment) && (profile.uuid === '190cfa98-b378-4ff8-8dbc-3e233d76091a')"
                        :to="{ name: 'saleInvestment', params: { uuid: investment.uuid } }"
                        class="btn btn-success">Продать
                    </router-link>
                    <span v-if="investment.sumForSale && investment.sumForSale.amount === 0" class="text-muted">На продаже</span>
                    <span v-else-if="investment.guarantorPercent" class="text-muted">С гарантией откупа</span>
                  </div>
                </div>
              </div>
            </div>
          </paginated-list>
        </div>
        <div class="card-body block-offer">
          <div class="h6">Оферты вторичного рынка</div>
          <paginated-list :count="offerCollection.count"
                          :total-count="offerCollection.totalCount"
                          :data-loading="offerCollection.loading"
                          :prefix="offerCollection.prefix"
                          @update="loadOfferCollection"
          >
            <div v-for="(item, index) in offerCollection.items" class="row row-body">
              <div class="col-name">
                <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">Проект</div>
                <router-link :to="{ name: 'primaryMarketProject', params: { uuid: item.project.uuid } }">
                  {{ item.project.name }}
                </router-link>
                <div class="small text-muted">ID {{ item.project.paymentCode }}</div>
              </div>
              <div class="col-buy-back">
                <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">&nbsp;</div>
                <img v-if="item.guarantorPercent" src="@/assets/img/bay_back.png" alt="Buy Back">
              </div>
              <div class="col-investment-money">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Инвестиция</div>
                <money :money="item.investmentMoney"/>
              </div>
              <div class="col-interest-rate">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Ставка</div>
                {{ item.project.interestRate }}%
              </div>
              <div class="col-month">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Месяц</div>
                {{ item.nextRepayment ? item.nextRepayment.month : item.project.initialTerm }}
                / {{ item.project.initialTerm }}
              </div>
              <div class="col-price">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Цена</div>
                <money :money="item.price"/>
              </div>
              <div :class="{ 'text-danger': item.expiredAt && isExpired(item.expiredAt) }" class="col-expired-at">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Истекает</div>
                <date-format :date="item.expiredAt"/>
                <div v-if="item.expiredAt && isExpired(item.expiredAt)" class="small">Истек</div>
              </div>
              <div class="col-actions">
                <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">&nbsp;</div>
                <button @click="cancelOffer(index)" type="button" class="btn btn-outline-info">Отменить</button>
              </div>
            </div>
          </paginated-list>
        </div>
        <div class="card-body block-active">
          <div class="h6">Завершенные</div>
          <paginated-list :count="paidCollection.count"
                          :total-count="paidCollection.totalCount"
                          :data-loading="paidCollection.loading"
                          :prefix="paidCollection.prefix"
                          @update="loadPaidCollection"
                          ref="activeList"
          >
            <div v-for="(item, index) in paidCollection.items" class="row row-body">
              <div class="col-toggle order-2 order-md-0">
                <button @click="toggleProjectVisible('active', index)" type="button" class="btn btn-sm btn-light">
                  <i :class="{
                    'bi-chevron-up': isProjectVisible('active', index),
                    'bi-chevron-down': !isProjectVisible('active', index)
                  }" class="bi"></i>
                </button>
              </div>
              <div class="col-name order-0 order-md-1">
                <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">Проект</div>
                <router-link :to="{ name: 'primaryMarketProject', params: { uuid: item.project.uuid } }">
                  {{ item.project.name }}
                </router-link>
                <div class="small text-muted">ID {{ item.project.paymentCode }}</div>
              </div>
              <div class="col-investment-money order-3 order-lg-2">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Инвестиция</div>
                <money :money="item.investedSum"/>
              </div>
              <div class="col-interest-rate order-4 order-lg-3">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Ставка</div>
                {{ calculateProjectPercent(item.project, item.investments) }}%
              </div>
              <div class="col-month order-1 order-md-2 order-lg-4">
                <div :class="{ 'd-lg-none': index > 0 }" class="small text-muted mb-0 mb-lg-3">Месяц</div>

                {{ item.project.initialTerm }}
              </div>
              <div v-if="isProjectVisible('active', index)" class="investment-list col-12 order-last">
                <div v-for="investment in item.investments" class="row mt-3">
                  <div class="col-buy-back order-4 order-lg-1">
                    <div :class="{ 'd-lg-block': index === 0 }" class="small text-muted d-none mb-0 mb-lg-3">&nbsp;
                    </div>
                  </div>
                  <div class="col-investment-date text-muted small order-3 order-lg-2">
                    <date-format :date="investment.createdAt"/>
                  </div>
                  <div class="col-investment-money order-0 order-lg-3">
                    <div>
                      <money :money="investment.money"/>
                    </div>
                  </div>
                  <div class="col-interest-rate order-1 order-lg-4">
                    {{ item.project.interestRate - investment.guarantorPercent }}%
                  </div>
                </div>
              </div>
            </div>
          </paginated-list>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {DataCollection, DateFormat, Money, PaginatedList} from "@/components/common";
import {createInvestmentManager, createOfferSaleManager} from "@/utils/di";
import moment from "moment";
import {Money as ApiMoney} from "@/utils/api-client/models/common/Money";
import CTooltip from "@/components/common/components/CTooltip.vue";

export default {
  name: "MyInvestmentsList",
  inject: ["profile"],
  components: {
    CTooltip,
    DateFormat,
    Money,
    PaginatedList
  },
  data() {
    return {
      pendingCollection: new DataCollection("p_"),
      activeCollection: new DataCollection("a_"),
      offerCollection: new DataCollection("o_"),
      paidCollection: new DataCollection("paid_"),
      projectVisibleStates: []
    };
  },
  methods: {
    investmentLeft(item) {
      let principal = 0;
      console.log(item);
      if (item.principalRepayment) {
        principal = item.principalRepayment.amount;
      }
      const investment = item.money.amount;
      return new ApiMoney(investment - principal, item.money.currencyCode);
    },
    investmentAvailableForSale(investment) {
      if (investment.principalRepayment) {
        if (investment.principalRepayment.amount > 0) {
          return false;
        }
      }
      if (!investment.isPrimary) {
        return true;
      }
      if (this.profile.isIndividual) {
        return !investment.isPrimary;
      }
      return true;
    },
    async loadPendingCollection(page, perPage) {
      this.pendingCollection.startLoading();
      try {
        const manager = await createInvestmentManager();
        const collection = await manager.getListFundRaisingOwned(page, perPage);
        this.pendingCollection.setCollection(collection);
      } finally {
        this.pendingCollection.finishLoading();
      }
    },
    async loadActiveCollection(page, perPage) {
      this.activeCollection.startLoading();
      try {
        const manager = await createInvestmentManager();
        const collection = await manager.getListLoanPaymentOwned(page, perPage);
        this.activeCollection.setCollection(collection); // items.filter((item) => item.sumForSale.amount > 0)
      } finally {
        this.activeCollection.finishLoading();
      }
    },
    async loadOfferCollection(page, perPage) {
      this.offerCollection.startLoading();
      try {
        const manager = await createOfferSaleManager();
        const collection = await manager.getListForSale(page, perPage);
        this.offerCollection.setCollection(collection);
      } finally {
        this.offerCollection.finishLoading();
      }
    },
    async loadPaidCollection(page, perPage) {
      this.paidCollection.startLoading();
      try {
        const manager = await createInvestmentManager();
        const collection = await manager.getListPaidOwned(page, perPage);
        this.paidCollection.setCollection(collection);
      } finally {
        this.paidCollection.finishLoading();
      }
    },
    calculateProjectPercent(project, investments) {
      //requirement: show lender correct percent (of investment, not project)
      //we agreed that lenders always buy investments on fixed price
      if (project) {
        if (investments && investments[0] && investments[0].guarantorPercent) {
          return (project.interestRate - investments[0].guarantorPercent)
        }
        return project.interestRate
      }
    },
    calculateDaysLeft(maturityDate) {
      let daysLeft = moment(maturityDate).diff(new Date(), "days");
      if (isNaN(daysLeft) || daysLeft < 0) {
        daysLeft = 0;
      }
      const reminder = daysLeft % 10;
      const dayCaptions = {1: "день", 2: "дня", 3: "дня", 4: "дня"};
      const dayCaption = dayCaptions[reminder] || "дней";
      return `${daysLeft} ${dayCaption}`;
    },
    hasDoubleRate(item) {
      for (let investment of item.investments) {
        if (investment.hasDoubleRate) {
          return true;
        }
      }
      return false;
    },
    isExpired(date) {
      const diff = moment(date).diff(new Date());
      return !isNaN(diff) && diff <= 0;
    },
    cancelOffer(index) {
      const offer = this.offerCollection.items[index];
      if (offer) {
        this.$confirm("Подтвердить отмену данной оферты вторичного рынка?", async () => {
          this.offerCollection.startLoading();
          try {
            const manager = await createOfferSaleManager();
            await manager.cancel(offer.uuid);
            this.offerCollection.delete(index);
          } finally {
            this.offerCollection.finishLoading();
          }
          this.$refs.activeList.update();
        });
      }
    },
    isProjectVisible(block, index) {
      return this.projectVisibleStates.includes(`${block}:${index}`);
    },
    toggleProjectVisible(block, index) {
      const name = `${block}:${index}`;
      const i = this.projectVisibleStates.indexOf(name);
      if (i > -1) {
        this.projectVisibleStates.splice(i, 1);
      } else {
        this.projectVisibleStates.push(name)
      }
    }
  }
};
</script>

<style lang="scss">
.my-investments-list {
  .card {
    .card-body {
      border-top: 0.25rem solid #ecedef;
    }

    .card-body:nth-child(2) {
      border-top: none;
    }
  }

  .row-body {
    margin-top: 1rem;
  }

  .row-body:not(:first-child) {
    border-top: 0.125rem solid #ecedef;
    padding-top: 0.5rem;
  }

  .col-buy-back img {
    height: 2.5rem;
  }

  .block-pending {
    .col-toggle,
    .col-name,
    .col-investment-date,
    .col-investment-money,
    .col-interest-rate,
    .col-progress,
    .col-initial-term,
    .col-left-days {
      padding: 1%;
    }

    .col-toggle {
      width: 10%;
      text-align: right;
    }

    .col-name {
      width: 90%;
    }

    .col-progress {
      width: 100%;
    }

    .col-investment-money,
    .col-interest-rate,
    .col-initial-term,
    .col-left-days {
      width: 25%;
      text-align: center;
    }

    .investment-list {
      margin-top: 1rem;

      .col-investment-date {
        order: 1;
        width: 75%;
        text-align: right;
        padding-top: 0.45rem;
      }

      .col-investment-money {
        order: 0;
        text-align: left;
      }
    }

    .col-progress .progress {
      height: 0.25rem;
    }

    @media (min-width: 768px) { // Bootstrap MD
      .col-toggle {
        width: 5%;
        text-align: left;
      }

      .col-name {
        width: 45%;
      }

      .col-progress {
        width: 50%;
      }

      .col-investment-money,
      .col-interest-rate,
      .col-initial-term,
      .col-left-days {
        width: 25%;
      }

      .investment-list {
        .col-investment-date {
          order: 0;
          width: 25%;
          margin-left: 25%;
          padding-top: 0.7rem;
        }

        .col-investment-money {
          order: 1;
          text-align: center;
        }
      }
    }

    @media (min-width: 992px) { // Bootstrap LG
      .col-name {
        width: 25%;
      }

      .col-investment-money {
        width: 12%;
      }

      .col-interest-rate {
        width: 10%;
      }

      .col-progress {
        width: 28%;
      }

      .col-initial-term {
        width: 10%;
      }

      .col-left-days {
        width: 10%;
      }

      .investment-list {
        .col-investment-date {
          width: 30%;
          margin-left: 0;
          padding-top: 0.85rem;
        }

        .col-investment-money {
          width: 12%;
        }
      }
    }
  }

  .block-active {
    .col-toggle,
    .col-name,
    .col-buy-back,
    .col-investment-money,
    .col-interest-rate,
    .col-month,
    .col-next-payment-sum,
    .col-next-payment-date,
    .col-actions {
      padding: 1%;
    }

    .col-toggle {
      width: 10%;
      text-align: right;
    }

    .col-name {
      width: 65%;
    }

    .col-investment-money,
    .col-interest-rate,
    .col-month,
    .col-next-payment-sum,
    .col-next-payment-date,
    .col-actions {
      text-align: center;
    }

    .col-investment-money,
    .col-interest-rate,
    .col-month,
    .col-next-payment-sum,
    .col-next-payment-date {
      width: 25%;
    }

    .col-actions {
      width: 100%;
    }

    .investment-list {
      .col-investment-date {
        width: 15%;
        padding-top: 0.45rem;
      }

      .col-buy-back {
        width: 10%;
        padding-top: 0;
      }
    }

    @media (min-width: 768px) { // Bootstrap MD
      .col-toggle {
        width: 5%;
      }

      .col-name {
        width: 45%;
      }

      .investment-list {
        .col-investment-date {
          padding-top: 0.7rem;
        }
      }
    }

    @media (min-width: 992px) { // Bootstrap LG
      .col-name {
        width: 25%;
      }

      .col-buy-back {
        width: 5%;
      }

      .col-investment-money,
      .col-next-payment-sum,
      .col-next-payment-date,
      .col-actions {
        width: 12%;
      }

      .col-interest-rate,
      .col-month {
        width: 11%;
      }

      .investment-list {
        .col-investment-date {
          width: 20%;
          padding-top: 0.85rem;
          text-align: right;
        }

        .col-next-payment-sum {
          margin-left: 11%;
        }

        .col-actions {
          margin-left: 12%;
        }
      }
    }
  }

  .block-offer {
    .col-name,
    .col-buy-back,
    .col-investment-money,
    .col-interest-rate,
    .col-month,
    .col-price,
    .col-expired-at,
    .col-actions {
      padding: 1%;
    }

    .col-name {
      width: 65%;
    }

    .col-buy-back {
      width: 10%;
    }

    .col-investment-money,
    .col-interest-rate,
    .col-month,
    .col-price,
    .col-expired-at,
    .col-actions {
      text-align: center;
    }

    .col-investment-money,
    .col-interest-rate,
    .col-month,
    .col-price,
    .col-expired-at {
      width: 25%;
    }

    .col-actions {
      width: 100%;
    }

    @media (min-width: 768px) { // Bootstrap MD
      .col-name {
        width: 40%;
      }

      .col-actions {
        width: 25%;
      }
    }

    @media (min-width: 992px) { // Bootstrap LG
      .col-name {
        width: 25%;
      }

      .col-buy-back {
        width: 5%;
      }

      .col-investment-money,
      .col-price,
      .col-expired-at,
      .col-actions {
        width: 12%;
      }

      .col-interest-rate,
      .col-month {
        width: 11%;
      }
    }
  }
}
</style>
